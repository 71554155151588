import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { NgxPaginationModule } from 'ngx-pagination';
import { AuthGuard } from '../app/authentication/guards/auth.guard';
import { provideUserIdleConfig, UserIdleService } from 'angular-user-idle';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PageLoaderComponent } from './spinner_css/page-loader/page-loader.component';

@NgModule({
  declarations: [
    AppComponent,
    PageLoaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      timeOut: 1500,
    }),
    NgxPaginationModule,
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
  ],
  providers: [
    AuthGuard,
    UserIdleService,
    provideUserIdleConfig({
      idle: 60,
      timeout: 0,
      ping: 0,
    }),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
